import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogContent, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../components/hook-form';
import { PATH_PAGE } from '../routes/paths';
import axios from '../utils/axios';

const PoPMessageForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const isSubmitTrue = localStorage.getItem('isSubmitTrue');

  let timer;

  useEffect(() => {
    if (!isSubmitTrue) {
      timer = setTimeout(() => {
        setOpen(true);
      }, 5000);
    } else {
      localStorage.setItem('isSubmitTrue', true);
    }
    return () => clearTimeout(timer);
  }, [isSubmitTrue, open]);

  const handleClose = () => {
    setOpen(false);
  };

  const UpdateUserSchema = Yup.object().shape({
    customerName: Yup.string().required('Name is required'),
    quoteEmail: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    quoteMobile: Yup.string()
      .matches(/^[0-9]+$/, 'Only numeric values are allowed')
      .min(10, 'Mobile number must be at least 10 digits')
      .max(15, 'Mobile number cannot exceed 15 digits')
      .required('Mobile number is required'),
  });

  const defaultValues = {
    customerName: '',
    quoteEmail: '',
    quoteMobile: '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await axios.post('/postQuote', data);
      enqueueSnackbar('Send success!');
      reset();
      localStorage.setItem('isSubmitTrue', true);
      navigate(PATH_PAGE.submitForm);
      setOpen(false); 
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to send, please try again!', { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: 'grid',
              p: 2,
              rowGap: 1,
              gap: 2,
              columnGap: 2,
              justifyContent: 'center',
              alignItems: 'center',
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
            }}
          >
            <Typography variant="h5" sx={{ textAlign: 'center', color: '#05406C' }}>
              Get in Touch With Us
            </Typography>
            <RHFTextField
              name="customerName"
              label="Name"
              size="small"
              error={!!errors.customerName}
              helperText={errors.customerName?.message}
            />
            <RHFTextField
              name="quoteMobile"
              label="Phone"
              type="tel"
              size="small"
              inputProps={{ maxLength: 15 }}
              error={!!errors.quoteMobile}
              helperText={errors.quoteMobile?.message}
            />
            <RHFTextField
              name="quoteEmail"
              label="Email"
              size="small"
              error={!!errors.quoteEmail}
              helperText={errors.quoteEmail?.message}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingButton
                type="submit"
                sx={{ background: '#2065D1', width: '120px' }}
                variant="contained"
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Box>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default PoPMessageForm;


// import { useSnackbar } from 'notistack';
// import { useEffect, useState, useRef } from 'react';
// import * as Yup from 'yup';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { Dialog, DialogContent, Typography, Box } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import { FormProvider, RHFTextField } from '../components/hook-form';
// import axios from '../utils/axios';
// import Iconify from '../components/Iconify';

// const PoPMessageForm = () => {
//   const { enqueueSnackbar } = useSnackbar();

//   const [open, setOpen] = useState(false);

//   const isCheck = useRef(true);

//   const handleClose = () => {
//     setOpen(false);
//   };

//   // useEffect(() => {
//   //   window.addEventListener('scroll', () => {
//   //     if (isCheck.current) {
//   //       setOpen(true);
//   //       isCheck.current = false;
//   //     }
//   //   });

//   //   return () => {
//   //     window.removeEventListener('scroll');
//   //   };
//   // }, []);

//   let clear = null;
//   clear = setTimeout(() => {
//     setOpen(true);
//   }, [5000]);

//   useEffect(() => {
//     if (open) {
//       clearTimeout(clear);
//     }
//   }, [open]);

//   const UpdateUserSchema = Yup.object().shape({
//     customerName: Yup.string().required('name is required'),
//     quoteEmail: Yup.string().email().required('email is required'),
//     quoteMobile: Yup.string().required('mobile number is required'),
//   });

//   const defaultValues = {
//     customerName: '',
//     quoteEmail: '',
//     quoteMobile: '',
//   };

//   const methods = useForm({
//     resolver: yupResolver(UpdateUserSchema),
//     defaultValues,
//   });

//   const {
//     handleSubmit,
//     formState: { isSubmitting },
//   } = methods;

//   const onSubmit = async (data) => {
//     try {
//       await axios.post(`/postQuote`, data);
//       setOpen(false);
//       enqueueSnackbar('send success!');
//       setOpen(false);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <Dialog open={open} onClose={handleClose}>
//       <Box
//         onClick={handleClose}
//         sx={{ display: 'flex', justifyContent: 'end', paddingRight: 2, paddingTop: 2, marginBottom: -5 }}
//       >
//         <Iconify icon={'ph:x'} sx={{ width: 30, height: 30, opacity: 0.1 }} />
//       </Box>
//       <DialogContent>
//         <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
//           <Box
//             sx={{
//               display: 'grid',
//               p: 2,
//               rowGap: 1,
//               gap: 2,
//               columnGap: 2,
//               justifyContent: 'center',
//               alignItems: 'center',
//               gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
//             }}
//           >
//             <Typography variant="h5" sx={{ textAlign: 'center', color: '#05406C' }}>
//               Get in Touch With Us
//             </Typography>
//             <RHFTextField name="customerName" label="Name" size="small" />
//             <RHFTextField name="quoteMobile" label="Phone" size="small" />
//             <RHFTextField name="quoteEmail" label="Email" size="small" />
//             <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//             >
//               <LoadingButton
//                 type="submit"
//                 sx={{ background: '#2065D1', width: '120px' }}
//                 variant="contained"
//                 loading={isSubmitting}
//               >
//                 submit
//               </LoadingButton>
//             </Box>
//           </Box>
//         </FormProvider>
//       </DialogContent>
//     </Dialog>
//   );
// };

// export default PoPMessageForm;
