import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  accountMode: [],
  partyName: [],
  invoiceList: [],
  payment: [],
  paymentEdit: null,
  paymentSetting:{}
};

const slice = createSlice({
  name: 'reciept',
  initialState,
  reducers: {
    setAccountMode(state, action) {
      state.accountMode = action.payload;
    },
    setPartyName(state, action) {
      state.partyName = action.payload;
    },
    setInvoiceList(state, action) {
      state.invoiceList = action.payload;
    },
    setPayment(state, action) {
      state.payment = action.payload;
    },
    setPaymentEdit(state, action) {
      state.paymentEdit = action.payload;
    },
    setPaymentSetting(state, action) {
      state.paymentSetting = action.payload;
    },
  },
});

export default slice.reducer;

export const {setPaymentSetting, setAccountMode, setPartyName, setInvoiceList, setPayment, setPaymentEdit } = slice.actions;

export const getPaymentSetting = () => async (dispatch) => {
  try {
    await axios.get('/payment/setting').then((response) => dispatch(setPaymentSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAccountMode = () => async (dispatch) => {
  try {
    await axios.get('/payment/accountMode').then((response) => dispatch(setAccountMode(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPartyName = () => async (dispatch) => {
  try {
    await axios.get('/payment/partyName').then((response) => dispatch(setPartyName(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoiceList = (id) => async (dispatch) => {
  try {
    await axios
      .get(`/payment/invoices?type=PAY&ledger_ID=${id}`)
      .then((response) => dispatch(setInvoiceList(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPayment = () => async (dispatch) => {
  try {
    await axios.get('/payment/paymentAll').then((response) => dispatch(setPayment(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPaymentEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/payment/payment?ID=${id}`).then((response) => dispatch(setPaymentEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
