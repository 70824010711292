import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  accountMode: [],
  partyName: [],
  invoiceList: [],
  reciept: [],
  recieptEdit: null,
  recieptSummary: null,
  receiptSetting: {},
  voucherNo: null,
};

const slice = createSlice({
  name: 'reciept',
  initialState,
  reducers: {
    setVoucherNo(state, action) {
      state.voucherNo = action.payload;
    },
    setAccountMode(state, action) {
      state.accountMode = action.payload;
    },
    setPartyName(state, action) {
      state.partyName = action.payload;
    },
    setInvoiceList(state, action) {
      state.invoiceList = action.payload;
    },
    setReciept(state, action) {
      state.reciept = action.payload;
    },
    setRecieptEdit(state, action) {
      state.recieptEdit = action.payload;
    },
    setRecieptSummary(state, action) {
      state.recieptSummary = action.payload;
    },
    setReceiptSetting(state, action) {
      state.receiptSetting = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setVoucherNo,
  setReceiptSetting,
  setAccountMode,
  setRecieptSummary,
  setPartyName,
  setInvoiceList,
  setReciept,
  setRecieptEdit,
} = slice.actions;

export const getVoucherNo = (typ, trans) => async (dispatch) => {
  try {
    await axios.get(`/reciept/voucherNo?typ=${typ}&tranType=${trans}`).then((response) => dispatch(setVoucherNo(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getReceiptSetting = () => async (dispatch) => {
  try {
    await axios.get('/reciept/setting').then((response) => dispatch(setReceiptSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getRecieptSummary = (sd, ed) => async (dispatch) => {
  try {
    await axios
      .get(`/reciept/summary/RES?start_date=${sd}&end_date=${ed}`)
      .then((response) => dispatch(setRecieptSummary(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getAccountMode = () => async (dispatch) => {
  try {
    await axios.get('/reciept/accountMode').then((response) => dispatch(setAccountMode(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPartyName = () => async (dispatch) => {
  try {
    await axios.get('/reciept/partyName').then((response) => dispatch(setPartyName(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoiceList = (id) => async (dispatch) => {
  try {
    await axios
      .get(`/reciept/invoices?type=RES&ledger_ID=${id}`)
      .then((response) => dispatch(setInvoiceList(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getReciept = () => async (dispatch) => {
  try {
    await axios.get('/reciept/recieptAll').then((response) => dispatch(setReciept(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getRecieptEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/reciept/reciept?ID=${id}`).then((response) => dispatch(setRecieptEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
